import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import TimeAgo from 'timeago-react';
import IconButton from '@mui/material/IconButton';
import MessageIcon from '@mui/icons-material/Message';
import ClearIcon from '@mui/icons-material/Clear';
import { getMediaFile } from '../../../core/api';
import './BookingRow.scss';
import Status from '../../Status/Status';
import BaseRow, { IBaseRowProps } from '../BaseRow/BaseRow';
import UserImage from '../../UserImage/UserImage';
import { IBookingRow } from '../../../core/types';
import Video from '../../Video/Video';
import TimeStatus from '../../TimeStatus/TimeStatus';
import { getExperienceThumbnails } from '../../../core/helpers/model-helpers';
import Chip from '@mui/material/Chip';
import { AdminActionTaken } from '../../../core/backend/models';

dayjs.extend(relativeTime);

interface IProps extends IBaseRowProps {
  booking: IBookingRow;
  isActive: boolean;
  onPreviewExperience: React.MouseEventHandler;
  onPreviewProvider: React.MouseEventHandler;
  onPreviewCustomer: React.MouseEventHandler;
  onAddComment: React.MouseEventHandler;
  onJustify: (justification: AdminActionTaken) => void;
}

const BookingRow = ({
  booking,
  isActive,
  onAddComment,
  onJustify,
  onPreviewExperience,
  onPreviewCustomer,
  onPreviewProvider
}: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleJustificationSelect = (justification: AdminActionTaken) => () => {
    onJustify(justification);
  };

  const forceWarningStatus =
    booking.status === 'pending' &&
    booking.remainingTime <= booking.hoursUntilEvent / 2;

  const { staticImageUrl, videoUrl } = getExperienceThumbnails(
    booking.gig.gigMainMediaFiles ?? []
  );

  return (
    <BaseRow selected={isActive}>
      <TableCell component='th' scope='row' style={{ verticalAlign: 'top' }}>
        <div className='BookingRow__videoRow' onClick={onPreviewExperience}>
          <Video
            className='BookingRow__video'
            coverUrl={staticImageUrl}
            url={videoUrl}
          />
          <p>
            <b>{booking.gig.name}</b>
          </p>
        </div>
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ width: '100px' }}
      >
        <p className='BookingRow__cellRow'>
          <b>{booking.reservationId}</b>
        </p>
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ width: '110px' }}
      >
        <p className='BookingRow__cellRow'>
          {booking.formattedRequestTimeDate}
        </p>
        <p className='BookingRow__cellRow'>{booking.formattedRequestTime}</p>
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ width: '110px' }}
      >
        <p className='BookingRow__cellRow'>
          {booking.formattedEstimatedDeadlineDate}
        </p>
        <p className='BookingRow__cellRow'>
          {booking.formattedEstimatedDeadlineTime}
        </p>
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ width: '110px' }}
      >
        <p className='BookingRow__cellRow'>{booking.formattedEventTimeDate}</p>
        <p className='BookingRow__cellRow'>{booking.formattedEventTime}</p>
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ width: '60px', maxWidth: '70px' }}
      >
        {/* {booking.formattedRemainingTime} */}
        <TimeStatus
          forceWarningStatus={forceWarningStatus}
          remainingHours={booking.remainingTime}
          hoursUntilEvent={booking.hoursUntilEvent}
        />
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ width: '80px' }}
      >
        <Status status={booking.status} />
        <div>{booking.statusDescriptor}</div>
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top', maxWidth: '140px' }}
      >
        <div className='BookingRow__userInfo' onClick={onPreviewProvider}>
          <UserImage
            className='BookingRow__image'
            url={getMediaFile(booking.gig.provider.image)}
          />
          <div className='BookingRow__userFullName'>
            {booking.gig.provider.fullName}
          </div>
        </div>
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top', maxWidth: '140px' }}
      >
        <div className='BookingRow__userInfo' onClick={onPreviewCustomer}>
          <UserImage
            className='BookingRow__image'
            url={getMediaFile(booking.customer.image)}
          />
          <div className='BookingRow__userFullName'>
            {booking.customer.fullName}
            {booking.expressCheckout ? (
              <Chip
                label='Guest'
                size='small'
                sx={{
                  backgroundColor: '#b83af3',
                  color: '#FFFFFF'
                }}
              />
            ) : null}
          </div>
        </div>
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top', maxWidth: '120px' }}
      >
        <IconButton onClick={onAddComment} title='Add Comments'>
          <MessageIcon />
        </IconButton>
        <IconButton onClick={handleOpenMenu} title='Cancel Booking'>
          <ClearIcon />
        </IconButton>
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          disableScrollLock={true}
        >
          <MenuItem onClick={handleJustificationSelect(1)}>
            Notified Creator
          </MenuItem>
          <MenuItem onClick={handleJustificationSelect(2)}>
            Creator Responded
          </MenuItem>
          <MenuItem onClick={handleJustificationSelect(3)}>
            Cannot Reach Creator
          </MenuItem>
        </Menu>
      </TableCell>
    </BaseRow>
  );
};

export default BookingRow;
